.payment-card {
  max-width: 100%;
  color: #000000;
  /* margin-bottom: 1em; */
  font-weight: 500;
  padding: 20px;
  margin-bottom: 15px;
  border: 0.5px solid;
  border-color: #e6e6e6;
  border-radius: 5px;
}

.cc-img {
  vertical-align: bottom;
  height: 25px;
  margin-right: 7px;
  border-radius: 10%;
}

.bank-img {
  vertical-align: bottom;
  height: 25px;
  margin-right: 7px;
  border-radius: 10%;
}

.card-method {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
}

.ach {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
}

.ach-pending-verification {
  vertical-align: middle;
  font-size: 16px;
  color: #aaa;
  float: right;
  margin-right: 10px;
}

.payment-card-delete-icon {
  cursor: pointer;
  float: right;
  font-size: 18px;
  margin-top: 5px;
}

.payment-card-delete-icon:hover {
  margin-bottom: 10px;
  padding-bottom: 10px;
  color: #999999;
}

.logout-button {
  color: #ababab;
  font-family: Sofia Pro;
  font-size: 15px;
  float: right;
  margin-top: 0.3em;
  margin-bottom: 30px;
  cursor: pointer;
}

.line-text-box {
  font-size: 13px;
  background-color: #ffffff;
  margin-top: 7px;
  width: 50% !important;
  resize: none;
  border-radius: 5px;
  padding: 7px;
  padding-left: 12px;
  font-weight: 500;
  color: rgb(97, 97, 97);
  border: 1px solid #d6d6d6;
  font-family: Avenir Next;
  white-space: pre-wrap;
  outline: none;
}

.account-action-button {
  float: right;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* Media Queries */
@media screen and (max-width: 480px) {
  .line-text-box {
    width: 100% !important;
  }

  .account-action-button {
    float: none;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .line-text-box {
    width: 65% !important;
  }
}