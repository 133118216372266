.redirect-section {
  font-size: 30px;
  margin-top: 5%;
  color: #000000;
  font-family: 'Avenir';
}

.redirect-action-text {
  cursor: pointer;
  color: rgb(55, 133, 237);
}

.signup-icon {
  color: rgba(0, 0, 0, 0.25);
}

.logo-image {
  height: 45px;
  padding-top: 10px;
  display: block;
  margin: auto;
  margin-bottom: 30px;
}