.add-bank-div {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
}

.add-cc-div {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.add-payment-img {
  height: 200px;
}

.partner-message {
  padding-top: 20px;
  color: #5a7685;
  line-height: 30px;
  font-size: 16px;
  font-family: Avenir Next;
  font-weight: 100;
}

.add-credit-card-form-item {
  color: #5a7685;
  line-height: 30px;
  font-size: 15px;
  font-family: Avenir Next;
  font-weight: 100;
  margin-bottom: 5px;
}

.add-payment-cancel-button {
  margin-right: 10px;
}

.stripe-input {
  box-shadow: none;
  padding-top: 6px;
}

.plaid-msg-div {
  padding-top: 40px;
  color: #5a7685;
  line-height: 30px;
  font-size: 16px;
  font-family: Avenir Next;
  font-weight: 100;
  margin-bottom: 20px;
}

.add-bank-cancel-button {
  margin-top: 20px;
  float: right;
  cursor: pointer;
}

.account-holder-name {
  padding-top: 10px;
  color: #5a7685;
  line-height: 30px;
  font-size: 18px;
  font-family: Sofia Pro;
  font-weight: 200;
}

@media (max-width: 767px),
(max-height: 400px) {
  .ant-modal {
    top: 0px !important;
  }
}