.animate {
  animation: left-to-right-slide 0.9s infinite;
  animation-timing-function: linear;
}

@keyframes left-to-right-slide {
  0% {
    transform: translate(-70px, 0);
  }
  100% {
    transform: translate(70px, 0);
  }
}
