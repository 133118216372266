.development-mode-label {
  position: fixed;
  font-family: 'Avenir Next';
  font-size: 12px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #dcdbdb;
  color: #7c7b7b;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px;
  z-index: 1;
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: static !important;
  color: #ffffff !important;
  box-shadow: none !important;
  border-bottom: 1px solid #e4e4e4 !important;
  background-color: #ffffff !important;
}

.user-name {
  font-family: 'Brown';
  color: #5a7685;
  font-size: 13px;
  font-weight: 300;
  text-decoration: none;
}

.user-company {
  font-family: 'Brown';
  color: #a8a8a8;
  font-size: 12px;
  font-weight: 300;
}

.user-text-section {
  background-color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.user-image-section {
  background-color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  margin-top: 3px;
}

.user-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.user-initials {
  color: #f56a00 !important;
  font-size: 15px;
  height: 35px;
  width: 35px;
  background-color: #fde3cf !important;
  vertical-align: middle;
}

.account-section {
  padding-top: 2px;
  padding-right: 10px;
  flex: 0.3;
  text-align: right;
  flex: 1;
}

.app-link {
  color: #272727;
  font-size: 16px;
  font-weight: 500;
  font-family: Avenir Next;
}